import type { MobxDocument } from '../firestore-mobx'

import type { FirebaseRepository } from './FirebaseRepository'
import { makeObservable } from 'mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty } from '../firestore/SettingsSuperAdmin/schema'
import type { FirestoreSettingsSuperAdmin } from '../firestore/SettingsSuperAdmin/schema'
import type { SuperAdminPrivilege } from '../types'

export class SettingsSuperAdmin extends ObservableModelWithDecorators<FirestoreSettingsSuperAdmin> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSettingsSuperAdmin>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  hasPrivilege(privilege: SuperAdminPrivilege) {
    if (!this.data) return false
    return this.data[privilege]
  }
}
