import type { DocumentReference } from 'firebase/firestore'
import {
  doc,
  type DocumentData,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { modelItemStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SettingsSuperAdmin } from '../../models/SettingsSuperAdmin'
import type { FirestoreSettingsSuperAdmin } from './schema'
import { schema } from './schema'

const converter: FirestoreDataConverter<FirestoreSettingsSuperAdmin> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getDocRef = (
  firestore: Firestore,
  userId: string
): DocumentReference<FirestoreSettingsSuperAdmin, DocumentData> => {
  return doc(
    firestore,
    'settings',
    'super_admin',
    'admins',
    userId
  ).withConverter(converter)
}

export const getSettingsSuperAdmin = (repository: FirebaseRepository) => {
  const ref = getDocRef(repository.firestore, repository.uid)
  return modelItemStream(repository, ref, SettingsSuperAdmin)
}
