import {
  collection,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
  type CollectionReference,
} from 'firebase/firestore'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreRoomStateEvent } from './schema'
import { RoomStateEvent } from '../../models/RoomStateEvent'
import { createBatchGet } from '../../util/batch-get'

const converter: FirestoreDataConverter<FirestoreRoomStateEvent> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    const eventTime = data.eventTime.toDate()
    return {
      ...data,
      eventTime,
    } as FirestoreRoomStateEvent
  },
}

const getColRef = (
  firestore: Firestore,
  roomId: string
): CollectionReference<FirestoreRoomStateEvent> => {
  return collection(firestore, 'room_state', roomId, 'events').withConverter(
    converter
  )
}

export const fetchRoomStateEvents = async (
  repository: FirebaseRepository,
  { roomId, eventIds }: { roomId: string; eventIds: string[] }
) => {
  const colRef = getColRef(repository.firestore, roomId)

  return createBatchGet({
    repository,
    collectionRef: colRef,
    ids: eventIds,
    modelClass: RoomStateEvent,
  })
}
