import z from 'zod'
import { nullSafeFirestoreTimestamp } from '../shared/schema'
import { InstitutionIntegrationType } from './types'

const emptyCanvasIntegrationSettings = z.object({})
const canvasIntegrationSettingsSchema = z.object({
  canvasClientId: z.string().optional(),
  canvasInstanceHostname: z.string().optional(),
  canvasAuthorizationUrl: z.string().optional(),
  canvasTokenUrl: z.string().optional(),
})

const otherIntegrationSchema = z.object({
  myConfigOption: z.string(),
})

const settingsSchema = canvasIntegrationSettingsSchema
  .or(otherIntegrationSchema)
  .or(emptyCanvasIntegrationSettings)

export const schema = z.object({
  integrationType: z.nativeEnum(InstitutionIntegrationType),
  integrationSettings: settingsSchema,
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const writeSchema = schema.extend({})

export const empty = Object.freeze(
  schema.parse({
    integrationType: InstitutionIntegrationType.canvas,
    integrationSettings: {},
  })
)

export type FirestoreInstitutionIntegration = z.infer<typeof schema>
export type InstitutionIntegrationSettings = z.infer<typeof settingsSchema>
export type InstitutionIntegrationCanvasSettings = z.infer<
  typeof canvasIntegrationSettingsSchema
>
