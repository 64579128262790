import z from 'zod'
import {
  nullSafeFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
} from '../shared/schema'

export const schema = z.object({
  eventId: z.string(),
  copiedFromRoomId: nullSafeOptionalString(),
  duration: nullSafeOptionalNumber(),
  confidence: nullSafeOptionalNumber(),
  eventTime: nullSafeFirestoreTimestamp(),
  eventType: z.number(),
  position: nullSafeOptionalNumber(),
  slideId: nullSafeOptionalString(),
  transcript: nullSafeOptionalString(),
  userId: z.string(),
})

export const empty = Object.freeze(
  schema.parse({
    eventId: '',
    duration: null,
    confidence: null,
    eventTime: null,
    eventType: 0,
    slideId: null,
    transcript: null,
    userId: '',
  })
)

export type FirestoreRoomStateEvent = z.infer<typeof schema>
