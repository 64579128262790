import type { DocumentData, CollectionReference } from 'firebase/firestore'
import { query, where, documentId } from 'firebase/firestore'
import type {
  ObservableModel,
  ObservableModelClass,
} from '../firestore-mobx/model'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { getDocsWithError } from '../firestore-mobx/fetch'
import { convertDocumentSnapshotToModel } from '../firestore-mobx/stream'

const MAX_BATCH_SIZE = 20

const batchGet = async <T>(
  ids: string[],
  batchSize: number,
  getFn: (batchIds: string[]) => Promise<T[]>
): Promise<T[]> => {
  if (batchSize > MAX_BATCH_SIZE) {
    throw new Error(`Batch size cannot exceed ${MAX_BATCH_SIZE}`)
  }

  const batches = []
  for (let i = 0; i < ids.length; i += batchSize) {
    batches.push(getFn(ids.slice(i, i + batchSize)))
  }

  const results = await Promise.all(batches)
  return results.flat()
}

type BatchGetOptions<D extends DocumentData, M extends ObservableModel<D>> = {
  repository: FirebaseRepository
  collectionRef: CollectionReference<D>
  ids: string[]
  modelClass: ObservableModelClass<D, M>
}

export const createBatchGet = <
  D extends DocumentData,
  M extends ObservableModel<D>,
>({
  repository,
  collectionRef,
  ids,
  modelClass,
}: BatchGetOptions<D, M>): Promise<M[]> => {
  if (ids.length === 0) return Promise.resolve([])

  const getBatch = async (batchIds: string[]) => {
    const q = query(collectionRef, where(documentId(), 'in', batchIds))
    const snapshot = await getDocsWithError(q, `Fetch${modelClass.name}Error`)
    return snapshot.docs.map((doc) =>
      convertDocumentSnapshotToModel(repository, doc, modelClass)
    )
  }

  return batchGet(ids, 10, getBatch)
}
