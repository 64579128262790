import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

export const schema = z.object({
  consumed: z.boolean(),
  sectionId: z.string(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  userId: nullSafeOptionalString(),
})

export const empty = Object.freeze(
  schema.parse({
    consumed: false,
    sectionId: '',
  })
)

export type FirestoreSectionPassCoupon = z.infer<typeof schema>
