import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  PartialWithFieldValue,
  QueryFilterConstraint,
} from 'firebase/firestore'
import {
  and,
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  increment,
  or,
  query,
  serverTimestamp,
  where,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import type { ObservableModelDocument } from '../../firestore-mobx/model'
import {
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'

import { DateTime } from 'luxon'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { RoomState } from '../../models/RoomState'
import type { FirestoreRoomState } from './schema'
import { schema, writeSchema } from './schema'
import { DocumentDoesNotExistError } from '../../types'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  getDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'

export * from './schema'

export interface RoomStateObservableModelDocument
  extends ObservableModelDocument<RoomState, FirestoreRoomState> {}

const converter: FirestoreDataConverter<FirestoreRoomState> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreRoomState>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreRoomState> => {
  return collection(firestore, 'room_state').withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  id: string
): DocumentReference<FirestoreRoomState, DocumentData> => {
  return doc(getColRef(firestore), id)
}

export const roomStateSetGroupLeaders = async (
  firestore: Firestore,
  roomId: string,
  userIds: string[]
) => {
  const ref = getDocRef(firestore, roomId)

  return updateDocWithError(
    ref,
    {
      groupLeaderUserIds: userIds,
    },
    'RoomStateSetGroupLeadersError'
  )
}

export const roomStateAddGroupLeader = async (
  firestore: Firestore,
  roomId: string,
  userId: string
) => {
  const ref = getDocRef(firestore, roomId)

  return updateDocWithError(
    ref,
    {
      groupLeaderUserIds: arrayUnion(userId),
    },
    'RoomStateAddGroupLeaderError'
  )
}

export const roomStateUpdateActiveSlide = async (
  firestore: Firestore,
  params: {
    roomId: string
    slideIndex: number
  }
) => {
  const ref = getDocRef(firestore, params.roomId)

  return updateDocWithError(
    ref,
    {
      activeSlide: params.slideIndex,
      activeSlideChangedAt: serverTimestamp(),
      activeExhibitId: null,
    },
    'RoomStateUpdateActiveSlideError'
  )
}

// perform the action associated with the slide change again
export const roomStateUpdatePumpSlide = async (
  firestore: Firestore,
  params: {
    roomId: string
  }
) => {
  const ref = getDocRef(firestore, params.roomId)

  return updateDocWithError(
    ref,
    {
      pumpSlide: increment(1),
      updatedAt: serverTimestamp(),
    },
    'RoomStateUpdateActiveSlideError'
  )
}

export const roomStateTouchActiveSlideChangedAt = async (
  firestore: Firestore,
  roomId: string
) => {
  const ref = getDocRef(firestore, roomId)

  return updateDocWithError(
    ref,
    {
      activeSlideChangedAt: serverTimestamp(),
    },
    'TouchActiveSlideChangedAtError'
  )
}

export const updateRoomState = async (
  firestore: Firestore,
  roomId: string,
  data: PartialWithFieldValue<FirestoreRoomState>
) => {
  const ref = getDocRef(firestore, roomId)

  return updateDocWithError(ref, data, 'UpdateRoomStateError')
}

export const roomStateAddUser = async (
  firestore: Firestore,
  params: {
    roomId: string
    userId: string
    isGroupLeader: boolean
  }
) => {
  const ref = getDocRef(firestore, params.roomId)

  const data: PartialWithFieldValue<FirestoreRoomState> = {
    userIds: arrayUnion(params.userId),
    updatedAt: serverTimestamp(),
  }

  if (params.isGroupLeader) {
    data['groupLeaderUserIds'] = arrayUnion(params.userId)
  }

  return updateDocWithError(ref, data, 'RoomStateAddUserError')
}

export const roomStateAddHiddenUser = async (
  firestore: Firestore,
  params: {
    roomId: string
    userId: string
  }
) => {
  const ref = getDocRef(firestore, params.roomId)

  const data: PartialWithFieldValue<FirestoreRoomState> = {
    hiddenUserIds: arrayUnion(params.userId),
  }

  return updateDocWithError(ref, data, 'RoomStateAddHiddenUserError')
}

export const leaveRoom = async (
  firestore: Firestore,
  params: { roomId: string; userId: string }
) => {
  const ref = getDocRef(firestore, params.roomId)

  await updateDocWithError(
    ref,
    {
      userIds: arrayRemove(params.userId),
      groupLeaderUserIds: arrayRemove(params.userId),
      updatedAt: serverTimestamp(),
    },
    'LeaveRoomError'
  )
}

export const createRoomState = async (
  firestore: Firestore,
  data: {
    userId?: string
    scheduled?: Date
    name?: string
    assignmentId: string
    sectionId: string
    slideDeckId: string
  }
) => {
  const ref = getColRef(firestore)

  const roomStateName = data.name || ''

  return addDocWithError(
    ref,
    {
      activeSlide: null,
      assignmentId: data.assignmentId,
      groupLeaderUserIds: data.userId ? [data.userId] : [],
      hiddenUserIds: [],
      roomStateName: roomStateName,
      scheduledAt: data.scheduled,
      sectionId: data.sectionId,
      slideDeckId: data.slideDeckId,
      updatedAt: serverTimestamp(),
      userIds: data.userId ? [data.userId] : [],
    },
    'CreateRoomStateError'
  )
}

export const createDemoRoomState = async (
  repository: FirebaseRepository,
  data: {
    userId: string
    scheduledAt: DateTime
    name?: string
    slideDeckId: string
  }
) => {
  const ref = getColRef(repository.firestore)

  const roomStateName = data.name || ''

  return addDocWithError(
    ref,
    {
      activeSlide: null,
      activeSlideChangedAt: null,
      groupLeaderUserIds: [data.userId],
      hiddenUserIds: [],
      roomStateName: roomStateName,
      scheduledAt: data.scheduledAt.toJSDate(),
      isDemo: true,
      slideDeckId: data.slideDeckId,
      updatedAt: serverTimestamp(),
      userIds: [data.userId],
    },
    'CreateDemoRoomStateError'
  )
}

export const fetchRoomState = async (
  repository: FirebaseRepository,
  {
    roomStateId,
    returnEmptyOnNotExists = false,
  }: { roomStateId: string; returnEmptyOnNotExists?: boolean }
) => {
  const docRef = getDocRef(repository.firestore, roomStateId)

  const doc = await getDocWithError(docRef, 'FetchRoomStateError')

  if (!doc.exists()) {
    if (returnEmptyOnNotExists) return RoomState.empty(repository)
    throw new DocumentDoesNotExistError()
  }

  return convertDocumentSnapshotToModel(repository, doc, RoomState)
}

export const getRoomStatesForStudent = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const userId = repository.uid
  const q = query(
    ref,
    or(
      where('userIds', 'array-contains', userId),
      where('groupLeaderUserIds', 'array-contains', userId)
    )
  )
  return modelListStream(repository, q, RoomState)
}

export const getRoomStates = (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const ref = getColRef(repository.firestore)
  const q = query(
    ref,
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  return modelListStream(repository, q, RoomState)
}

export const fetchRoomStates = async (
  repository: FirebaseRepository,
  { sectionId, assignmentId }: { sectionId: string; assignmentId: string }
) => {
  const ref = getColRef(repository.firestore)
  const q = query(
    ref,
    and(
      where('sectionId', '==', sectionId),
      where('assignmentId', '==', assignmentId)
    )
  )

  const snapshot = await getDocsWithError(q, 'FetchRoomStatesError')

  return snapshot.docs.map((doc) => {
    return convertDocumentSnapshotToModel(repository, doc, RoomState)
  })
}

export const getRoomState = (
  repository: FirebaseRepository,
  { roomStateId }: { roomStateId: string }
) => {
  const docRef = getDocRef(repository.firestore, roomStateId)

  return modelItemStream(repository, docRef, RoomState)
}

export const getRoomStatesRunning = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const timeToCheck = DateTime.now().minus({ minutes: 30 }).toJSDate()

  const q = query(ref, where('activeSlideChangedAt', '>', timeToCheck))
  return modelListStream(repository, q, RoomState)
}

export const getRoomStatesScheduled = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  const timeToCheck = DateTime.now().minus({ hours: 48 }).toJSDate()

  const q = query(
    ref,
    and(
      where('activeSlide', '==', null),
      where('scheduledAt', '>', timeToCheck)
    )
  )
  return modelListStream(repository, q, RoomState)
}

/**
 * request to start the ai processing. This is done automatically when the last user leaves
 * a room, but this will allow it to be rerun by admin
 */
export const forceAIProcessing = async (
  repository: FirebaseRepository,
  {
    roomId,
  }: {
    roomId: string
  }
) => {
  const collectionRef = getColRef(repository.firestore)
  const docRef = doc(collectionRef, roomId)
  const aiRecordsCollectionRef = collection(
    repository.firestore,
    docRef.path,
    'ai'
  )
  const aiRecordsQuery = query(
    aiRecordsCollectionRef,
    where('endedAt', '==', null)
  )
  const aiRecords = await getDocsWithError(
    aiRecordsQuery,
    'FetchForForceAIProcessingError'
  )
  if (!aiRecords.docs.length) {
    await addDocWithError(
      aiRecordsCollectionRef,
      {
        startedAt: serverTimestamp(),
        roomId: roomId,
        endedAt: null,
      },
      'ForceAIProcessingError'
    )
    return true
  }
  return false
}

/** sets active slide and active slide changes at to null */
export const roomStateReset = async (
  repository: FirebaseRepository,
  {
    roomId,
  }: {
    roomId: string
  }
) => {
  return await updateDocWithError(
    getDocRef(repository.firestore, roomId),
    {
      activeSlide: null,
      activeSlideChangedAt: null,
      activeExhibitId: null,
      roomStartedAt: null,
    },
    'RoomStateResetError'
  )
}

export const getDemoRoomStates = (
  repository: FirebaseRepository,
  {
    mode,
  }: {
    mode: 'upcoming' | 'completed'
  }
) => {
  const ref = getColRef(repository.firestore)
  const recentTimeToCheck = DateTime.now()
    .toUTC()
    .minus({ minutes: 60 })
    .toJSDate()

  const predicates: Array<QueryFilterConstraint> = [where('isDemo', '==', true)]
  if (mode === 'upcoming') {
    predicates.push(
      or(
        where('activeSlideChangedAt', '>', recentTimeToCheck),
        where('activeSlideChangedAt', '==', null)
      )
    )
  } else {
    const weekAgo = DateTime.now().toUTC().minus({ days: 7 }).toJSDate()
    predicates.push(where('activeSlideChangedAt', '<=', recentTimeToCheck))
    predicates.push(where('activeSlideChangedAt', '>', weekAgo))
  }

  const q = query(ref, and(...predicates))

  return modelListStream(repository, q, RoomState)
}

export const adminStartDemoMeeting = async (
  repository: FirebaseRepository,
  {
    roomStateId,
  }: {
    roomStateId: string
  }
) => {
  const roomStateRef = getDocRef(repository.firestore, roomStateId)
  return updateDocWithError(
    roomStateRef,
    {
      activeSlide: 0,
      activeSlideChangedAt: new Date(),
      roomStartedAt: new Date(),
      groupLeaderUserIds: [repository.uid],
      userIds: arrayUnion(repository.uid),
    },
    'AdminStartDemoMeetingError'
  )
}

/**
 * There is no demo specific logic for this, the as now the rules only allow for deletion
 * of demo roomStates
 */
export const deleteDemoRoomState = async (
  repository: FirebaseRepository,
  {
    roomStateId,
  }: {
    roomStateId: string
  }
) => {
  const roomStateRef = getDocRef(repository.firestore, roomStateId)
  await deleteDocWithError(roomStateRef, 'DeleteDemoRoomStateError')
}
