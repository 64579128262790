import type { User } from 'firebase/auth'
import type { Firestore } from 'firebase/firestore'
import { computed, makeObservable, observable } from 'mobx'
import { Cubit } from '../cubits/core'
import type { StaticModelCollection } from '../firestore-mobx/model'
import { getUserProfilePurchases } from '../firestore/UserProfilePurchase'
import { getUserProfileTokens } from '../firestore/UserProfileToken'
import type { BreakoutUser } from '../models/BreakoutUser'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { UserProfilePurchase } from '../models/UserProfilePurchase'
import { UserProfileToken } from '../models/UserProfileToken'
import { fetchSlideDeck } from '../firestore/SlideDeck'
import type { SlideDeck } from '../models/SlideDeck'
import { UserProfileSectionPass } from '../models/UserProfileSectionPass'
import { getSectionPasses } from '../firestore/UserProfileSectionPass'

// TODO: move to stores
export class UserProfileTokens extends Cubit {
  firestore: Firestore
  repository: FirebaseRepository
  user: BreakoutUser | User

  _tokensCollection: StaticModelCollection<UserProfileToken>
  _purchaseCollection: StaticModelCollection<UserProfilePurchase>
  _sectionPassCollection: StaticModelCollection<UserProfileSectionPass>

  @observable
  private _mappedSlideDecksToConsumedTokens:
    | Record<string, SlideDeck>
    | undefined = undefined

  constructor(repository: FirebaseRepository, user: BreakoutUser | User) {
    super()
    makeObservable(this)
    this.user = user
    this.repository = repository
    this.firestore = repository.firestore
    this._tokensCollection = UserProfileToken.emptyCollection(repository)
    this._purchaseCollection = UserProfilePurchase.emptyCollection(repository)
    this._sectionPassCollection =
      UserProfileSectionPass.emptyCollection(repository)
  }

  startTokenStreamIfNotStarted() {
    const name = 'tokens'
    // only start once
    if (this.hasStream(name)) return

    this.addStream(
      getUserProfileTokens(this.repository, { userId: this.user.uid }),
      (tokens) => {
        this._tokensCollection.replaceModels(tokens)
      },
      { name }
    )
  }

  startPurchasesStreamIfNotStarted() {
    const name = 'purchases'
    // only start once
    if (this.hasStream(name)) return

    this.addStream(
      getUserProfilePurchases(this.repository, { userId: this.user.uid }),
      (purchases) => {
        this._purchaseCollection.replaceModels(purchases)
      },
      { name }
    )
  }

  startSectionPassesStreamIfNotStarted() {
    const name = 'sectionPasses'
    // only start once
    if (this.hasStream(name)) return

    this.addStream(
      getSectionPasses(this.repository, { userId: this.user.uid }),
      (sectionPasses) => {
        this._sectionPassCollection.replaceModels(sectionPasses)
      },
      { name }
    )
  }

  async fetchConsumedTokenSlideDecks() {
    const consumedTokens = this.tokens.filter((token) => token.data.consumed)

    const slideDecksMappedToTokens: Record<string, SlideDeck> = {}

    await Promise.all(
      consumedTokens.map(async (token) => {
        if (!token.data.slideDeckId) return

        const deck = await fetchSlideDeck(this.repository, {
          slideDeckId: token.data.slideDeckId,
        })

        slideDecksMappedToTokens[token.id] = deck
      })
    )

    this._mappedSlideDecksToConsumedTokens = slideDecksMappedToTokens
  }

  @computed
  get mappedSlideDecksToConsumedTokens() {
    if (
      typeof this._mappedSlideDecksToConsumedTokens === 'undefined' &&
      this.tokensCollection.length > 0
    ) {
      // We can safely assume that the slide decks have not been fetched yet.
      this.fetchConsumedTokenSlideDecks()
    }

    return this._mappedSlideDecksToConsumedTokens
  }

  get tokensCollection() {
    this.startTokenStreamIfNotStarted()
    return this._tokensCollection
  }

  get purchaseCollection() {
    this.startPurchasesStreamIfNotStarted()
    return this._purchaseCollection
  }

  get sectionPassesCollection() {
    this.startSectionPassesStreamIfNotStarted()
    return this._sectionPassCollection
  }

  @computed
  get isLoading() {
    return (
      this.tokensCollection.isLoading ||
      this.purchaseCollection.isLoading ||
      this.sectionPassesCollection.isLoading
    )
  }

  @computed
  get availableTokens() {
    let totalAvailable = 0
    for (const token of this.tokensCollection.documents) {
      if (token.data.consumed === true) continue
      totalAvailable +=
        token.data.tokenQuantity - (token.data.consumedCount || 0)
    }
    return totalAvailable
  }

  @computed
  get tokensAvailable() {
    return this.tokens
      .filter((token) => token.data.consumed === false)
      .reduce((acc, token) => acc + token.data.tokenQuantity, 0)
  }

  @computed
  get tokensConsumed() {
    return this.tokens
      .filter((token) => token.data.consumed === true)
      .reduce((acc, token) => acc + token.data.tokenQuantity, 0)
  }

  get tokens() {
    return this.tokensCollection.models
  }

  get purchases() {
    return this.purchaseCollection.models
  }

  get sectionPassSectionIds() {
    return new Set(this._sectionPassCollection.models.map((model) => model.id))
  }
}
