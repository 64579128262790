import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreSectionPassCoupon,
} from '../firestore/SectionPassCoupon/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class SectionPassCoupon extends ObservableModelWithDecorators<FirestoreSectionPassCoupon> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSectionPassCoupon>
  ) {
    super(repository, doc)
    makeObservable(this)
  }

  get consumed() {
    return this.doc.data.consumed
  }
}
