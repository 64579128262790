import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { Catalog } from '../models/Catalog'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import {
  addSectionToCatalog,
  deleteCatalog,
  getAllAppUserWithCatalogAccess,
  getCatalog,
  removeSectionFromCatalog,
  updateCatalog,
} from '../firestore/Catalog'
import { InvitationType } from '../types'
import { type StaticModelCollection } from '../types'
import { SlideDeck, SlideDeckState } from '../models/SlideDeck'
import {
  addCatalogToSlideDeck,
  getSlideDecks,
  getSlideDecksForCatalog,
} from '../firestore/SlideDeck'
import { SlideDeckGroup } from '../stores/SlideDeckGroup'
import type { AppUser } from '../stores/AppUser'
import { fetchAllAppUsersByRole } from '../firestore/PublicUser'
import {
  addCatalogToUserProfile,
  removeCatalogFromUserProfile,
} from '../firestore/UserProfile'
import { UserProfileRole } from '../firestore/UserProfile/types'
import { Section } from '../models/Section'
import {
  getSectionsStreamForInstructor,
  getSharedSections,
} from '../firestore/Section'
import { createInvitationInstructorWithCatalog } from '../firestore/Invitation'
import { fetchSettingsProtectedCatalogIds } from '../firestore/SettingsCatalogs'
import { TeachingPlan } from '../models/TeachingPlan'
import { createTeachingPlan, getTeachingPlans } from '../firestore/TeachingPlan'
import type { TeachingPlanAggregation } from '../models/TeachingPlanAggregation'
import { getTeachingPlanAggregation } from '../firestore/TeachingPlanAggregation'
import {
  addCatalogToOrganization,
  getOrganizations,
  getOrganizationsForCatalogWithInstructorIds,
  removeCatalogFromOrganization,
} from '../firestore/Organization'
import { Organization } from '../models/Organization'

export enum AdminCatalogTab {
  experiences = 'experiences',
  teaching_plans = 'teaching_plans',
  details = 'details',
}

export class AdminCatalogCubit extends Cubit {
  repository: FirebaseRepository

  @observable
  tab: AdminCatalogTab = AdminCatalogTab.experiences

  @observable
  detailsUsersTab: 'users' | 'organizations' = 'users'

  catalog: Catalog
  catalogId: string
  slideDecks: StaticModelCollection<SlideDeck>
  allSlideDecks: StaticModelCollection<SlideDeck>
  sections: StaticModelCollection<Section>
  teachingPlans: StaticModelCollection<TeachingPlan>
  teachingPlanAggregationByTeachingPlanId = observable.map<
    string,
    TeachingPlanAggregation
  >()

  private _sharedSections: StaticModelCollection<Section>
  private _catalogOrganizations: StaticModelCollection<Organization>

  // initialized late, if we want to add catalog to an organization
  private _allOrganizations: StaticModelCollection<Organization>

  private _instructorIdsByOrg: Map<string, string[]> = new Map()

  instructors = observable.array<AppUser>()
  protected = observable.array<string>()

  private _authorizedUsers = observable.array<AppUser>()

  @observable showHidden = false

  constructor(repository: FirebaseRepository, catalogId: string) {
    super()
    makeObservable(this)
    this.catalogId = catalogId
    this.repository = repository
    this.catalog = Catalog.empty(repository)
    this.slideDecks = SlideDeck.emptyCollection(repository)
    this.allSlideDecks = SlideDeck.emptyCollection(repository)
    this.sections = Section.emptyCollection(repository)
    this.teachingPlans = TeachingPlan.emptyCollection(repository)
    this._sharedSections = Section.emptyCollection(repository)
    this._catalogOrganizations = Organization.emptyCollection(repository)
    this._allOrganizations = Organization.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(
      getCatalog(this.repository, {
        catalogId: this.catalogId,
      }),
      (catalog) => {
        if (!catalog.hasData) {
          // this can happen on catalog delete
          this.catalog.replaceModel(Catalog.empty(this.repository))
          return this.catalog.changeLoadingState(true)
        }
        this.catalog.replaceModel(catalog)
      }
    )
    this.addStream(
      getTeachingPlans(this.repository, {
        catalogId: this.catalogId,
      }),
      (teachingPlans) => {
        this.teachingPlans.replaceModels(teachingPlans)
        this.getTeachingPlanAggregations()
      }
    )
    this.addStream(
      getSlideDecksForCatalog(this.repository, {
        catalogId: this.catalogId,
      }),
      (slideDecks) => {
        this.slideDecks.replaceModels(slideDecks)
      }
    )
    this.addStream(getSlideDecks(this.repository), (slideDecks) => {
      this.allSlideDecks.replaceModels(slideDecks)
    })
    this.addStream(
      getAllAppUserWithCatalogAccess(this.repository, this.catalogId),
      (authorizedUsers) => {
        this._authorizedUsers.replace(
          // filter out students and TAs
          authorizedUsers.filter(
            (user) =>
              user.userProfile.data.role !== UserProfileRole.student &&
              user.userProfile.data.role !== UserProfileRole.ta
          )
        )
      }
    )
    if (this.repository.breakoutUser?.isCorre) {
      fetchAllAppUsersByRole(this.repository, UserProfileRole.instructor).then(
        (instructors) => {
          this.instructors.replace(instructors)
        }
      )
    }
    this.addStream(
      getSectionsStreamForInstructor(this.repository),
      (sections) => {
        this.sections.replaceModels(sections)
      }
    )
    this.addStream(getSharedSections(this.repository), (sharedSections) => {
      this._sharedSections.replaceModels(sharedSections)
    })

    if (this.repository.breakoutUser?.isCorre) {
      this.addStream(
        getOrganizationsForCatalogWithInstructorIds(this.repository, {
          catalogId: this.catalogId,
        }),
        (orgsWithInstructors) => {
          const models: Organization[] = []
          const instructorIdsByOrg: Map<string, string[]> = new Map()
          orgsWithInstructors.forEach((org) => {
            models.push(org.organization)
            instructorIdsByOrg.set(org.organization.id, org.instructorIds)
          })
          this._catalogOrganizations.replaceModels(models)
          this._instructorIdsByOrg = instructorIdsByOrg
        }
      )
    }

    if (this.repository.breakoutUser?.isEditor) {
      fetchSettingsProtectedCatalogIds(this.repository).then((protectedIds) => {
        runInAction(() => {
          this.protected.replace(protectedIds)
        })
      })
    }
  }

  /**
   * return users with catalog access and whether or not they are an organization user
   */
  @computed
  get authorizedUsers() {
    if (this.organizations.isLoading || !this._authorizedUsers.length) return []
    const allOrgInstructorIds = new Set(
      Array.from(this._instructorIdsByOrg.values()).flat()
    )
    return this._authorizedUsers.map((user) => {
      return {
        ...user,
        isOrganizationUser: allOrgInstructorIds.has(user.uid),
      }
    })
  }

  /**
   * returns a list of organizations of which the catalog is included
   * includes the instructor count for each organization
   */
  @computed
  get organizations() {
    const models = this._catalogOrganizations.models.map((org) => {
      return {
        ...org,
        instructorCount: this._instructorIdsByOrg.get(org.id)?.length || 0,
      }
    })
    return {
      isLoading: this._catalogOrganizations.isLoading,
      models,
    }
  }

  @computed
  get sortedSectionOptions() {
    // Returns a list of sections sorted by class and section name.
    // Returned in a format that can be used by the BreakoutSelect component.
    return this.sections.models
      .map((section) => ({
        value: section.id,
        label: section.sectionLabelWithClass,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
  }

  @computed
  get sharedSectionForCatalog() {
    return {
      isLoading: this._sharedSections.isLoading || this.catalog.isLoading,
      models: this._sharedSections.models.filter((section) => {
        return this.catalog.data.catalogSharedSectionIds.includes(section.id)
      }),
    }
  }

  @computed
  get addableInstructors() {
    return this.instructors.filter((instructor) => {
      return !this._authorizedUsers.find((user) => {
        return user.uid === instructor.uid
      })
    })
  }

  @computed
  get slideDecksGroups() {
    const grouped = new Map<string, SlideDeckGroup>()

    const filtered = this.showHidden
      ? this.slideDecks.models
      : this.slideDecks.models.filter((slideDeck) => {
          return slideDeck.data.slideDeckState !== SlideDeckState.hidden
        })

    filtered.forEach((slideDeck) => {
      const parentId = slideDeck.data.slideDeckTypeId || slideDeck.id
      let group = grouped.get(parentId)

      if (!group) {
        group = new SlideDeckGroup(parentId)
        grouped.set(parentId, group)
      }

      group.addSlideDeck(slideDeck)
    })

    return Array.from(grouped.values()).sort((a, b) => {
      return b.updatedAt.getTime() - a.updatedAt.getTime()
    })
  }

  @computed
  get allSlideDecksGroups() {
    const grouped = new Map<string, SlideDeckGroup>()

    const existingSlideDeckIds = this.slideDecks.models.map(
      (slideDeck) => slideDeck.id
    )

    this.allSlideDecks.models.forEach((slideDeck) => {
      if (existingSlideDeckIds.includes(slideDeck.id)) return
      const parentId = slideDeck.data.slideDeckTypeId || slideDeck.id
      let group = grouped.get(parentId)

      if (!group) {
        group = new SlideDeckGroup(parentId)
        grouped.set(parentId, group)
      }

      group.addSlideDeck(slideDeck)
    })

    return Array.from(grouped.values()).sort((a, b) => {
      return b.updatedAt.getTime() - a.updatedAt.getTime()
    })
  }

  @computed
  get organizationsNotPartOfCatalog() {
    this.startOrganizationStreamIfNotStreaming()
    return {
      isLoading: this._allOrganizations.isLoading,
      models: this._allOrganizations.models.filter((organization) => {
        return !this._catalogOrganizations.models.find(
          (org) => org.id === organization.id
        )
      }),
    }
  }

  @action
  startOrganizationStreamIfNotStreaming() {
    const streamKey = 'allOrganizations'
    if (!this.hasStream(streamKey)) {
      this.addStream(getOrganizations(this.repository), (organizations) => {
        this._allOrganizations.replaceModels(organizations)
      })
    }
  }

  @computed
  get isCatalogProtected() {
    return this.protected.includes(this.catalogId)
  }

  @action
  changeTab(tab: AdminCatalogTab): void {
    this.tab = tab
  }

  @action
  changeDetailsUsersTab(tab: typeof this.detailsUsersTab): void {
    this.detailsUsersTab = tab
  }

  updateCatalog(params: {
    catalogName: string
    catalogDescription: string
  }): void {
    updateCatalog(this.repository, this.catalogId, params)
  }

  deleteCatalog(): void {
    // guard against deleting protected catalogs
    if (this.isCatalogProtected) return

    deleteCatalog(this.repository, this.catalogId)
  }

  addUser(userId: string) {
    addCatalogToUserProfile(this.repository, {
      userId,
      catalogId: this.catalogId,
    })
  }

  removeUser(userId: string) {
    removeCatalogFromUserProfile(this.repository, {
      userId,
      catalogId: this.catalogId,
    })
  }

  addSection(sectionId: string) {
    addSectionToCatalog(this.repository, {
      catalogId: this.catalogId,
      sectionId,
    })
  }

  removeSection(sectionId: string) {
    removeSectionFromCatalog(this.repository, {
      catalogId: this.catalogId,
      sectionId,
    })
  }

  addSlideDeck(slideDeckId: string) {
    addCatalogToSlideDeck(this.repository, {
      catalogId: this.catalogId,
      slideDeckId,
    })
  }

  getTeachingPlanAggregations() {
    const formatStreamName = (teachingPlanId: string) =>
      `teachingPlanAggregation-${teachingPlanId}`

    // Setup a stream for each aggregation.
    this.teachingPlans.models.forEach(async (teachingPlan) => {
      const streamName = formatStreamName(teachingPlan.id)
      if (this.hasStream(streamName)) {
        return
      }

      this.addStream(
        getTeachingPlanAggregation(this.repository, {
          catalogId: this.catalogId,
          teachingPlanId: teachingPlan.id,
        }),
        (aggregation) => {
          runInAction(() => {
            this.teachingPlanAggregationByTeachingPlanId.set(
              teachingPlan.id,
              aggregation
            )
          })
        },
        { name: streamName, onError: (err) => console.error(err) }
      )
    })
  }

  createTeachingPlan(teachingPlanName: string) {
    return createTeachingPlan(this.repository, this.catalogId, teachingPlanName)
  }

  async removeCatalogFromOrganization(organizationId: string) {
    return await removeCatalogFromOrganization(this.repository, {
      organizationId,
      catalogId: this.catalogId,
    })
  }

  async addCatalogToOrganization(organizationId: string) {
    return await addCatalogToOrganization(this.repository, {
      organizationId,
      catalogId: this.catalogId,
      defaultCatalogId: false,
    })
  }

  async createInvitation({ type }: { type: InvitationType }) {
    const doc = await createInvitationInstructorWithCatalog(this.repository, {
      catalogId: this.catalogId,
      oneTime: type === InvitationType.oneTime,
    })

    return doc.id
  }
}
