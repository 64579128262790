// User object that is shared publicly

import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreRoomStateRubricResult,
} from '../firestore/RoomStateRubricResult'
import type { FirebaseRepository } from './FirebaseRepository'
import { RubricType } from '../types'

export class RoomStateRubricResult extends ObservableModelWithDecorators<FirestoreRoomStateRubricResult> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateRubricResult>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get rubricId() {
    return this.id
  }

  /**
   * As of now this is only used for the Blooms rubric.
   * overrides the the justification with a
   * default message if the score is 0 to avoid displaying
   * the sometimes creative results from openAI
   */
  justificationForRubricType(
    rubricType: RubricType,
    t: (key: string) => string
  ) {
    if (rubricType === RubricType.blooms && this.data.score === 0)
      return t('instructor_assignment.blooms_empty_evaluation')
    return this.data.justification
  }
}
