import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  SectionRequestState,
  type FirestoreSectionRequest,
} from '../firestore/SectionRequest'
import type { FirebaseRepository } from './FirebaseRepository'
import { DateTime } from 'luxon'
import { formatCurrency } from '../util/currency'

export class SectionRequest extends ObservableModelWithDecorators<FirestoreSectionRequest> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSectionRequest>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  formatDate(mode: 'month_day_year' | 'time_am_pm_timezone') {
    const date = DateTime.fromJSDate(this.data.requestedAt)
    if (mode === 'month_day_year') {
      // Ex. "Jan 1, 2022"
      return date.toLocaleString(DateTime.DATE_MED)
    } else {
      // Ex. "1:00 PM PST"
      return (
        date.toLocaleString(DateTime.TIME_SIMPLE) + ' ' + date.offsetNameShort
      )
    }
  }

  get timeAndTimezone() {
    return this.formatDate('time_am_pm_timezone')
  }

  get monthDayYear() {
    return this.formatDate('month_day_year')
  }

  get isPending() {
    return this.data.sectionRequestState === SectionRequestState.pending
  }

  get isRejected() {
    return this.data.sectionRequestState === SectionRequestState.rejected
  }

  get estimatedCost() {
    const tokenPrice = this.repository.pricing.tokenPriceInt

    // Format as dollar amount.
    const amount =
      this.data.sectionRequestStudentCount *
      this.data.sectionRequestAssignmentCount *
      tokenPrice

    if (amount < 1) {
      return formatCurrency(0, 'USD', {
        maximumFractionDigits: 0,
      })
    }

    return formatCurrency(amount, 'USD', {
      maximumFractionDigits: 0,
    })
  }
}
