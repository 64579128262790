import z from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
} from '../shared/schema'

// this is the dart definition
// required this.consumed,
// required this.tokenQuantity, default 1
// this.assignmentId,
// this.consumedCount,
// this.paymentId,
// this.price,
// this.roomId,
// this.sectionId,
export const schema = z.object({
  createdAt: nullSafeOptionalFirestoreTimestamp(),
  paymentId: nullSafeOptionalString().nullable(),
  price: nullSafeOptionalNumber(),
  purchaseDate: nullSafeOptionalFirestoreTimestamp(),
  purchaseType: nullSafeOptionalString(),
  sectionId: nullSafeOptionalString(),
  creatorUserId: nullSafeOptionalString(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  userId: nullSafeOptionalString(),
})

export const empty = Object.freeze(schema.parse({}))

export type FirestoreUserProfileSectionPass = z.infer<typeof schema>
