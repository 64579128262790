import { makeObservable } from 'mobx'
import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import type { InstitutionIntegrationCanvasSettings } from '../firestore/InstitutionIntegration/schema'
import {
  empty,
  type FirestoreInstitutionIntegration,
} from '../firestore/InstitutionIntegration/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class InstitutionIntegration extends ObservableModelWithDecorators<FirestoreInstitutionIntegration> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreInstitutionIntegration>
  ) {
    super(repository, doc)

    makeObservable(this)
  }

  get canvasSettings() {
    return this.data.integrationSettings as InstitutionIntegrationCanvasSettings
  }
}
