import z from 'zod'
import { nullSafeOptionalFirestoreTimestamp } from '../shared/schema'

export const schema = z.object({
  state: z.string(),
  baseUrl: z.string().optional(),
  cloudStorageURI: z.string().optional(),
  createdAt: nullSafeOptionalFirestoreTimestamp(),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    state: '',
    createdAt: null,
    updatedAt: null,
  })
)

export type FirestoreRoomStateInspector = z.infer<typeof schema>
