interface CalculateTimeLeftParams<T extends { durationInMinutes: number }> {
  slides: T[]
  currentIndex?: number
  roundToNearest?: number
}

/**
 * Calculates the total time remaining in minutes for a list of slides after a given index
 * @param params Configuration object
 * @param params.slides Array of slides with durationInMinutes property
 * @param params.currentIndex Current slide index
 * @param params.roundToNearest Optional value to round up to nearest multiple (e.g., 5 rounds to nearest 5 minutes)
 * @returns Total minutes remaining in the session
 */
export function calculateTimeLeftInSession<
  T extends { durationInMinutes: number },
>(params: CalculateTimeLeftParams<T>): number {
  const { slides, currentIndex = 0, roundToNearest } = params

  let total = 0
  for (let i = currentIndex; i < slides.length; i++) {
    total += slides[i].durationInMinutes
  }

  if (roundToNearest === undefined) return total

  return Math.ceil(total / roundToNearest) * roundToNearest
}
