import { computed, makeObservable, observable } from 'mobx'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import type { StaticModelCollection } from '../types'
import { Cubit } from './core'
import { Institution } from '../models/Institution'
import { Organization } from '../models/Organization'
import {
  createInstitution,
  deleteInstitution,
  getInstitutions,
  updateInstitution,
} from '../firestore/Institution'
import { getOrganizations } from '../firestore/Organization'
import {
  createInstitutionIntegration,
  getInstitutionIntegrations,
  updateInstitutionIntegration,
} from '../firestore/InstitutionIntegration'
import { InstitutionIntegration } from '../models/InstitutionIntegration'
import { InstitutionIntegrationType } from '../firestore/InstitutionIntegration/types'
import type { InstitutionIntegrationCanvasSettings } from '../firestore/InstitutionIntegration/schema'

export class AdminInstitutionsCubit extends Cubit {
  repository: FirebaseRepository

  institutions: StaticModelCollection<Institution>
  organizations: StaticModelCollection<Organization>
  integrations = observable.map<
    string,
    StaticModelCollection<InstitutionIntegration>
  >()

  @observable showHidden = false

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)
    this.repository = repository
    this.institutions = Institution.emptyCollection(repository)
    this.organizations = Organization.emptyCollection(repository)
  }

  initialize(): void {
    this.addStream(getInstitutions(this.repository), (institutions) => {
      this.institutions.replaceModels(institutions)

      // add streams for integrations
      this.addIntegrationStreams(institutions)
    })
    this.addStream(getOrganizations(this.repository), (organizations) => {
      this.organizations.replaceModels(organizations)
    })
  }

  @computed
  get loaded() {
    return this.institutions.isLoaded && this.organizations.isLoaded
  }

  addIntegrationStreams(institutions: Institution[]) {
    const namespace = 'integrations'
    this.removeStreams(namespace)

    for (const institution of institutions) {
      this.addStream(
        getInstitutionIntegrations(this.repository, {
          institutionId: institution.id,
        }),
        (integrations) => {
          const found = this.integrations.get(institution.id)
          if (found) {
            found.replaceModels(integrations)
          } else {
            const collection = InstitutionIntegration.emptyCollection(
              this.repository
            )
            collection.replaceModels(integrations)
            this.integrations.set(institution.id, collection)
          }
        },
        { namespace: namespace }
      )
    }
  }

  createOrUpdateInstitution = async ({
    institutionId,
    institutionName,
  }: {
    institutionId?: string
    institutionName: string
  }) => {
    if (institutionId) {
      return await updateInstitution(this.repository, {
        institutionId,
        institutionName,
      })
    }
    await createInstitution(this.repository, { institutionName })
  }

  deleteInstitution = async ({ institutionId }: { institutionId: string }) => {
    await deleteInstitution(this.repository, { institutionId })
  }

  createOrUpdateCanvasIntegration = ({
    institutionId,
    integrationId,
    integrationSettings,
  }: {
    institutionId: string
    integrationId?: string
    integrationSettings: InstitutionIntegrationCanvasSettings
  }) => {
    const integrations = this.integrations.get(institutionId)
    const found = integrations?.models.find((i) => i.id === integrationId)

    if (found && integrationId) {
      return updateInstitutionIntegration(this.repository, {
        institutionId,
        integrationId,
        integrationSettings,
      })
    }

    return createInstitutionIntegration(this.repository, {
      institutionId,
      integrationType: InstitutionIntegrationType.canvas,
      integrationSettings,
    })
  }

  @computed
  get institutionsWithOrganizations() {
    return this.institutions.models.map((institution) => {
      const organizations = this.organizations.models.filter(
        (o) => o.data.institutionId === institution.id
      )
      return {
        institution,
        organizations,
      }
    })
  }
}
