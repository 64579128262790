import z from 'zod'
import {
  firestoreWriteTimestamp,
  nullSafeFirestoreTimestamp,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
} from '../shared/schema'

export enum AssignmentGroupingType {
  manual = 0,
  automaticRandom = 1,
}

export enum AssignmentType {
  studentLed = 0,
  instructorLed = 1,
}

export enum AssignmentState {
  unconfigured = -1,
  draft = 0,
  active = 1,
  canceled = 2,
}

// this is the dart definition
// required int assignedAt,
// required AssignmentType assignmentType,
// required int expiresAt,
// required AssignmentGroupingType groupingType, default: AssignmentGroupingType.none
// required String sectionId,
// required String slideDeckId,
// int? groupingSize,
// int? updatedAt,
export const schema = z.object({
  assignedAt: nullSafeOptionalFirestoreTimestamp(),
  assignmentType: z.nativeEnum(AssignmentType),
  assignmentGradingScalars: z
    .object({
      quizScore: z.number().optional(),
      participationScore: z.number().optional(),
      totalRubricScore: z.number().optional(),
    })
    .optional(),
  expiresAt: nullSafeOptionalFirestoreTimestamp(),
  forceScheduledAt: nullSafeOptionalFirestoreTimestamp(),
  groupingType: z.preprocess((val) => {
    return val ?? AssignmentGroupingType.manual
  }, z.nativeEnum(AssignmentGroupingType)),
  assignmentState: z.preprocess((val) => {
    return val ?? AssignmentState.active // default to active
  }, z.nativeEnum(AssignmentState)),
  sectionId: z.string(),
  slideDeckId: z.string(),
  groupingSize: nullSafeOptionalNumber(),
  groupingSizeMaximum: nullSafeOptionalNumber(),
  groupingSizeMinimum: nullSafeOptionalNumber(),
  catalogId: z.string().nullish(),
  updatedAt: nullSafeFirestoreTimestamp(),
})

export const writeSchema = schema.extend({
  assignedAt: firestoreWriteTimestamp,
  expiresAt: firestoreWriteTimestamp,
  updatedAt: firestoreWriteTimestamp,
})

export const draftWriteSchema = writeSchema.extend({
  assignedAt: firestoreWriteTimestamp.optional(),
  expiresAt: firestoreWriteTimestamp.optional(),
})

export const empty = Object.freeze(
  schema.parse({
    assignedAt: { seconds: 0, nanoseconds: 0 },
    assignmentType: AssignmentType.studentLed,
    expiresAt: { seconds: 0, nanoseconds: 0 },
    groupingType: AssignmentGroupingType.manual,
    sectionId: '',
    slideDeckId: '',
  })
)

type FirestoreSectionAssignmentInferredType = z.infer<typeof schema>

export type FirestoreSectionAssignmentWrite = z.infer<typeof writeSchema>

export interface FirestoreSectionAssignment
  extends FirestoreSectionAssignmentInferredType {}
