import {
  collection,
  doc,
  serverTimestamp,
  writeBatch,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { type FirestoreSectionPassCoupon, schema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { modelListStream } from '../../firestore-mobx/stream'
import { SectionPassCoupon } from '../../models/SectionPassCoupon'

const converter: FirestoreDataConverter<FirestoreSectionPassCoupon> = {
  toFirestore: (data) => data,
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (firestore: Firestore, sectionId: string) => {
  return collection(
    firestore,
    'section',
    sectionId,
    'section_pass_coupon'
  ).withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  {
    sectionId,
    couponId,
  }: {
    sectionId: string
    couponId: string
  }
) => {
  return doc(getColRef(firestore, sectionId), couponId)
}

export const getSectionPassCouponsForSection = (
  repository: FirebaseRepository,
  { sectionId }: { sectionId: string }
) => {
  return modelListStream(
    repository,
    getColRef(repository.firestore, sectionId),
    SectionPassCoupon
  )
}

export const createSectionPassCoupons = async (
  repository: FirebaseRepository,
  { sectionId, couponIds }: { sectionId: string; couponIds: string[] }
) => {
  if (couponIds.length > 500) throw new Error('max coupon write limit exceeded')
  const batch = writeBatch(repository.firestore)

  // iterate over ids and write new sectionPassCoupon to the docRef
  for (const couponId of couponIds) {
    const docRef = getDocRef(repository.firestore, {
      sectionId,
      couponId,
    })

    batch.set(docRef, {
      consumed: false,
      updatedAt: serverTimestamp(),
      sectionId,
    })
  }

  return await batch.commit()
}
