import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { Cubit } from './core'

export class NavigationCubit extends Cubit {
  readonly isMobile: boolean
  readonly animationDuration = 300 as const
  private readonly _animationsEnabled: boolean
  private _isAnimatingTimeout: NodeJS.Timeout | undefined

  @observable isAnimating = false

  /// only matters on mobile (if false, drawer open button renders)
  @observable
  private _mobileDrawerMounted: boolean = false

  @observable
  private _collapsed: boolean

  @observable
  helpHovered = false

  constructor({
    isMobile,
    animationsEnabled,
  }: {
    isMobile: boolean
    animationsEnabled: boolean
  }) {
    super()
    makeObservable(this)

    // start collapsed on mobile, expanded on desktop
    this._collapsed = isMobile
    this.isMobile = isMobile
    this._mobileDrawerMounted = false
    this._animationsEnabled = animationsEnabled
  }

  @computed
  get mobileDrawerMounted() {
    return this._mobileDrawerMounted
  }

  @computed
  get isClosed() {
    return this._collapsed
  }

  @computed
  get isOpen() {
    return !this._collapsed
  }

  @computed
  get isOpenAnimationInProgress() {
    return this.isAnimating && this.isOpen
  }

  @computed
  get isCloseAnimationInProgress() {
    return this.isAnimating && this.isClosed
  }

  @action
  setHelpHovered(hovered: boolean) {
    this.helpHovered = hovered
  }

  @action
  toggleCollapsed() {
    if (this._collapsed) return this.open()
    this.close()
  }

  @action
  open() {
    this._setAnimationState()
    runInAction(() => (this._collapsed = false))
    if (!this.isMobile) return
    runInAction(() => (this._mobileDrawerMounted = true))
  }

  @action
  close() {
    this._setAnimationState()
    runInAction(() => {
      this._collapsed = true
      if (this.isMobile) this.setHelpHovered(false)
    })

    if (!this.isMobile) return
    setTimeout(() => {
      runInAction(() => (this._mobileDrawerMounted = false))
    }, 200)
  }

  @action
  private _setAnimationState() {
    if (this.isMobile || !this._animationsEnabled) return // no open/close animations on mobile
    clearTimeout(this._isAnimatingTimeout)
    this.isAnimating = true
    this._isAnimatingTimeout = setTimeout(() => {
      runInAction(() => (this.isAnimating = false))
    }, this.animationDuration)
  }

  dispose() {
    clearTimeout(this._isAnimatingTimeout)
    return super.dispose()
  }
}
