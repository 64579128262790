import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreRoomStateEvent,
} from '../firestore/RoomStateEvent/schema'
import type { FirebaseRepository } from './FirebaseRepository'

export class RoomStateEvent extends ObservableModelWithDecorators<FirestoreRoomStateEvent> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateEvent>
  ) {
    super(repository, doc)
  }
}
