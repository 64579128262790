import type { CollectionReference, Firestore } from 'firebase/firestore'
import {
  collection,
  doc,
  serverTimestamp,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  addDocWithError,
  deleteDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { InstitutionIntegration } from '../../models/InstitutionIntegration'
import type { InstitutionIntegrationSettings } from './schema'
import { schema, type FirestoreInstitutionIntegration } from './schema'
import type { InstitutionIntegrationType } from './types'

const converter: FirestoreDataConverter<FirestoreInstitutionIntegration> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore,
  params: { institutionId: string }
): CollectionReference<FirestoreInstitutionIntegration> => {
  return collection(
    firestore,
    'institution',
    params.institutionId,
    'institution_integration'
  ).withConverter(converter)
}

export const getInstitutionIntegrations = (
  repository: FirebaseRepository,
  params: { institutionId: string }
) => {
  const ref = getColRef(repository.firestore, params)
  return modelListStream(repository, ref, InstitutionIntegration)
}

export const createInstitutionIntegration = async (
  repository: FirebaseRepository,
  {
    institutionId,
    integrationType,
    integrationSettings,
  }: {
    institutionId: string
    integrationType: InstitutionIntegrationType
    integrationSettings: InstitutionIntegrationSettings
  }
) => {
  await addDocWithError(
    getColRef(repository.firestore, {
      institutionId,
    }),
    {
      integrationType,
      integrationSettings: integrationSettings,
      updatedAt: serverTimestamp(),
    }
  )
}

export const updateInstitutionIntegration = async (
  repository: FirebaseRepository,
  {
    institutionId,
    integrationId,
    integrationSettings,
  }: {
    institutionId: string
    integrationId: string
    integrationSettings: InstitutionIntegrationSettings
  }
) => {
  const ref = doc(
    getColRef(repository.firestore, { institutionId }),
    integrationId
  )
  await updateDocWithError(ref, {
    integrationSettings,
    updatedAt: serverTimestamp(),
  })
}

export const deleteInstitutionIntegration = async (
  repository: FirebaseRepository,
  {
    institutionId,
    integrationId,
  }: { institutionId: string; integrationId: string }
) => {
  const ref = doc(
    getColRef(repository.firestore, { institutionId }),
    integrationId
  )
  await deleteDocWithError(ref)
}
