import { action, computed, makeObservable, observable } from 'mobx'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import {
  OrganizationState,
  type OrganizationInvoiceStatus,
  type StaticModelCollection,
} from '../types'
import { Cubit } from './core'
import { Organization } from '../models/Organization'
import { getInstitutions } from '../firestore/Institution'
import { Institution } from '../models/Institution'
import {
  createOrganization,
  getOrganizations,
  getOrganizationsWhereUserIsAdmin,
} from '../firestore/Organization'
import { getCatalogs } from '../firestore/Catalog'
import { Catalog } from '../models/Catalog'

export class OrganizationsCubit extends Cubit {
  repository: FirebaseRepository
  private _organizations: StaticModelCollection<Organization>
  catalogs: StaticModelCollection<Catalog>
  institutions: StaticModelCollection<Institution>

  @observable
  showArchived = false

  @observable
  filters = [] as string[]

  constructor(repository: FirebaseRepository) {
    super()
    makeObservable(this)
    this.repository = repository
    this._organizations = Organization.emptyCollection(repository)
    this.institutions = Institution.emptyCollection(repository)
    this.catalogs = Catalog.emptyCollection(repository)
  }

  initialize(): void {
    this.setupOrganizationStreams()
    if (this.repository.breakoutUser?.isCorre) {
      this.addStream(getInstitutions(this.repository), (institutions) => {
        this.institutions.replaceModels(institutions)
      })
      this.addStream(getCatalogs(this.repository), (catalogs) => {
        this.catalogs.replaceModels(catalogs)
      })
    }
    // If the user is not an admin, we don't need to fetch the institutions/catalogs
    // set empty to move loading state to false
    this.institutions.replaceModels([])
    this.catalogs.replaceModels([])
  }

  setupOrganizationStreams() {
    if (this.repository.breakoutUser?.isInternal) {
      // When the user is internal, we can just do a list query on all the organizations.
      this.addStream(getOrganizations(this.repository), (orgs) => {
        this._organizations.replaceModels(orgs)
      })
      return
    }

    // When the user is not internal, we need to get the organizations that the current user is an admin of.
    this.addStream(
      getOrganizationsWhereUserIsAdmin(
        this.repository,
        this.repository.breakoutUser?.uid || ''
      ),
      (orgs) => {
        this._organizations.replaceModels(orgs)
      }
    )
  }

  sortOrgsByInstNameAndOrgName = (orgs: Organization[]) => {
    // Sort by institution name first, then organization name.
    return orgs.sort((a, b) => {
      const aInstitution = a.data.organizationInstitution || ''
      const bInstitution = b.data.organizationInstitution || ''
      if (aInstitution === bInstitution) {
        return a.data.organizationName.localeCompare(b.data.organizationName)
      }
      return aInstitution.localeCompare(bInstitution)
    })
  }

  @action
  addFilter(filter: string) {
    // Prevent duplicates
    if (this.filters.includes(filter)) return
    this.filters.push(filter)
  }

  @action
  removeFilter = (filter: string) => {
    const newFilters = this.filters.filter((f) => f !== filter)
    this.filters = newFilters
  }

  @action
  toggleShowArchived() {
    this.showArchived = !this.showArchived
  }

  createOrganization(params: {
    organizationName: string
    organizationInstitution: string
    institutionId: string
    organizationInvoiceStatus: OrganizationInvoiceStatus
    catalogIds: string[]
  }): Promise<string> {
    return createOrganization(this.repository, {
      ...params,
    })
  }

  @computed
  get loading() {
    return (
      this._organizations.isLoading ||
      this.institutions.isLoading ||
      this.catalogs.isLoading
    )
  }

  @computed
  get organizations() {
    let filtered = this._organizations.models
    if (!this.showArchived) {
      // Only show active organizations
      filtered = filtered.filter(
        (org) => org.data.organizationState === OrganizationState.active
      )
    }

    if (this.filters.length > 0) {
      // Filter by organization name or institution.
      filtered = filtered.filter((org) => {
        const institutionName = org.data.organizationInstitution
        return this.filters.some(
          (filter) =>
            org.data.organizationName.includes(filter) ||
            institutionName?.includes(filter)
        )
      })
    }

    return this.sortOrgsByInstNameAndOrgName(filtered)
  }
}
