import type { MobxDocument } from '../firestore-mobx'
import type { FirebaseRepository } from './FirebaseRepository'
import { computed, makeObservable, reaction, runInAction } from 'mobx'
import {
  empty,
  SectionPaymentMethod,
  type FirestoreSection,
} from '../firestore/Section/schema'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { PublicUser } from './PublicUser'

// TODO: rename back to Section when the Section type is removed
export class Section extends ObservableModelWithDecorators<FirestoreSection> {
  instructor: PublicUser = PublicUser.empty(this.repository)

  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreSection>
  ) {
    super(repository, doc)

    // Watch for changes to the instructorUserId, to update the instructor.
    reaction(
      () => this.data.instructorUserId,
      (instructorUserId) => {
        if (!instructorUserId) return
        runInAction(() => {
          this.instructor = this.repository.userStore.getUser(instructorUserId)
        })
      },
      { fireImmediately: true }
    )
    makeObservable(this)
  }

  @computed
  get allUsers() {
    return this.repository.userStore.getUsers([])
  }

  @computed
  get students() {
    return this.repository.userStore.getUsers(this.data.userIds)
  }

  @computed
  get studentsLoaded() {
    if (this.data.userIds.length === 0) return true

    return this.students.every((user) => user.isLoaded)
  }

  @computed
  get userCount() {
    return this.data.userIds.length
  }

  @computed
  get isCanvasCourse() {
    return !!this.data.canvasCourseId
  }

  @computed
  get sectionLabelWithClass() {
    return `${this.data.className}: ${this.data.sectionName}`
  }

  @computed
  get usesSectionPass() {
    return this.data.sectionPaymentMethod > 0
  }

  @computed
  get usesStripePurchase() {
    return [
      SectionPaymentMethod.token,
      SectionPaymentMethod.sectionPassStripe,
      SectionPaymentMethod.sectionPassStripeCoupon,
    ].includes(this.data.sectionPaymentMethod)
  }

  @computed
  get usesCouponCodes() {
    return [
      SectionPaymentMethod.sectionPassCoupon,
      SectionPaymentMethod.sectionPassStripeCoupon,
    ].includes(this.data.sectionPaymentMethod)
  }
}
