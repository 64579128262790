import { action, computed, makeObservable, observable } from 'mobx'

import {
  createRoomStateInspectorRequest,
  getRoomStateInspectorData,
} from '../firestore/RoomStateInspector'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { RoomStateInspector } from '../models/RoomStateInspector'
import { Cubit } from './core'
import { getDownloadURL, ref } from 'firebase/storage'

type NormalizedRoomState = {
  id: string
  userIds: string[]
  roomStateName: string
  slideDeckId: string
}

type NormalizedUser = {
  id: string
  firstName: string
  lastName: string
  imageUrl: string
  role: 'student' | 'instructor' | 'admin' | 'ta'
}

type NormalizedSlide = {
  id: string
  slideName: string
  slideDescription: string
  slideOrder: number
  slideType: number
}

type LogType = {
  event_name: string
  event_date: string
  user_id: string
  event_params: Record<string, unknown>
  browser: string
  browser_version: string
}

type EventType = {
  transcript: string
  eventTime: string
  userId: string
  eventType: number
  duration: number
  slideId?: string
}

type ChatType = {
  authorId: string
  createdAt: string
  status: string
  text: string
  type: string
}

type FeedbackType = {
  score: number
  feedback: string
  comment: string
  userId: string
  flagMode: string
  reasons: string[]
}

type PayloadData = {
  roomState: NormalizedRoomState
  users: NormalizedUser[]
  slides: NormalizedSlide[]
  logs: LogType[]
  events: EventType[]
  chats: ChatType[]
  feedback: FeedbackType[]
}

export const EVENT_NAME_MAP: Record<number, string> = {
  0: 'Join Room',
  1: 'Leave Room',
  2: 'Transcript',
  3: 'Audio On',
  4: 'Audio Off',
  5: 'Video On',
  6: 'Video Off',
  7: 'Slide Change',
  8: 'Video Pause',
  9: 'Video Play',
  10: 'Video Seek',
  11: 'Video Stop',
  12: 'Recording Request',
  13: 'Recording Start',
  14: 'Recording Stop',
  15: 'Exhibit Show',
  16: 'Exhibit Hide',
  17: 'Pump Slide',
}

export class AdminInspectorCubit extends Cubit {
  repository: FirebaseRepository

  payload: RoomStateInspector

  @observable
  private _payloadData: PayloadData | null = null

  userLookup: Map<string, NormalizedUser> = observable.map<
    string,
    NormalizedUser
  >()

  roomId: string

  constructor(repository: FirebaseRepository, roomId: string) {
    super()
    makeObservable(this)
    this.roomId = roomId
    this.payload = RoomStateInspector.empty(repository)
    this.repository = repository
  }

  initialize(): void {
    this.addRoomStatePayloadStream()
  }

  addRoomStatePayloadStream() {
    this.addStream(
      getRoomStateInspectorData(this.repository, { roomId: this.roomId }),
      (payload) => {
        this.payload.replaceModel(payload)
      }
    )
  }

  requestRoomStatePayload() {
    return createRoomStateInspectorRequest(
      this.repository.firestore,
      this.roomId
    )
  }

  @computed
  get cloudStorageURI() {
    return this.payload.data?.cloudStorageURI
  }

  private _fetchingPayloadData = false
  fetchPayloadDataIfRequired() {
    if (!this.cloudStorageURI) return
    // if already fetched, return
    if (this._payloadData) return
    if (this._fetchingPayloadData) return

    this._fetchingPayloadData = true

    const payloadRef = ref(this.repository.storage, this.cloudStorageURI)
    getDownloadURL(payloadRef)
      .then((url) => {
        fetch(url).then(async (res) => {
          const json = await res.json()

          this.resetPayloadData(json)
        })
      })
      .finally(() => {
        this._fetchingPayloadData = false
      })
  }

  @action
  resetPayloadData(payloadData: PayloadData) {
    this._payloadData = payloadData
    for (const user of payloadData.users) {
      this.userLookup.set(user.id, user)
    }
  }

  @computed
  get payloadData(): PayloadData | null {
    this.fetchPayloadDataIfRequired()

    return this._payloadData
  }

  @computed
  get filteredEvents() {
    const events = this.payloadData?.events || []

    // right now, all filters are on
    const filtered = events
    //   .filter((event) => this.eventFilters.get(event.eventType))
    //   .filter((event) => this.userFilters.get(event.userId) || !event.userId)

    return filtered
  }

  @computed
  get filteredSortedEvents() {
    const events = this.filteredEvents || []

    const sorted = events.slice().sort((a, b) => {
      return a.eventTime.localeCompare(b.eventTime)
    })

    return sorted
  }

  @computed
  get slides() {
    return this.payloadData?.slides || []
  }
}
