import type { FirebaseRepository } from './FirebaseRepository'
import type { MobxDocument } from '../types'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import {
  empty,
  type FirestoreUserProfileSectionPass,
} from '../firestore/UserProfileSectionPass/schema'

export class UserProfileSectionPass extends ObservableModelWithDecorators<FirestoreUserProfileSectionPass> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreUserProfileSectionPass>
  ) {
    super(repository, doc)
  }

  get exists() {
    return !this.hasData && this.isLoaded && this.isNotEmpty
  }
}
