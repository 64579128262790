import { fetchRoomStateEvents } from '../firestore/RoomStateEvent'
import type { FirebaseRepository } from '../models/FirebaseRepository'
import { Cubit } from './core'
import { makeObservable } from 'mobx'

export class RoomStateEventsCubit extends Cubit {
  repository: FirebaseRepository
  roomStateId: string

  constructor(repository: FirebaseRepository, roomStateId: string) {
    super()
    this.repository = repository
    this.roomStateId = roomStateId

    makeObservable(this)
  }

  initialize(): void {}

  fetchRoomStateEvents = async (eventIds: string[]) => {
    return fetchRoomStateEvents(this.repository, {
      roomId: this.roomStateId,
      eventIds,
    })
  }
}
