import z from 'zod'
import {
  nullSafeBoolean,
  nullSafeOptionalBoolean,
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalNumber,
  nullSafeOptionalString,
  nullSafeString,
} from '../shared/schema'
import { UserProfileRole } from './types'

// this is the dart definition
// final String? emailAddress;
// final String? hubspotContactId;
// final int? hubspotDealId;
// final AppRole role;
// final bool userActive true;
// final bool onboardComplete false
export const schema = z.object({
  emailAddress: nullSafeString(''),
  canvasUserId: nullSafeOptionalString(),
  hubspotContactId: nullSafeOptionalString(),
  hubspotDealId: nullSafeOptionalNumber(),
  // initial value is set from hubspot, then can be updated by the user
  company: nullSafeOptionalString(),
  role: z.preprocess(
    (val) => (val as UserProfileRole) ?? UserProfileRole.student,
    z.nativeEnum(UserProfileRole)
  ),
  onboardComplete: nullSafeBoolean(false),
  walkthroughComplete: nullSafeBoolean(false),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
  userActive: nullSafeOptionalBoolean(true),
  hasPromotions: nullSafeOptionalBoolean(false),
  anonymous: nullSafeOptionalBoolean(false),
})

export const empty = Object.freeze(
  schema.parse({
    emailAddress: '',
    role: UserProfileRole.student,
    onboardComplete: false,
  })
)

export interface FirestoreUserProfile extends z.infer<typeof schema> {}
