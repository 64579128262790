import type {
  CollectionReference,
  DocumentData,
  DocumentReference,
  Firestore,
  Query,
} from 'firebase/firestore'
import {
  collection,
  collectionGroup,
  doc,
  query,
  runTransaction,
  serverTimestamp,
  where,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import {
  collectionSnapshots,
  convertDocumentSnapshotToModel,
  modelItemStream,
  modelListStream,
} from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import type { FirestoreOrganization, OrganizationInvoiceStatus } from './schema'
import { OrganizationState, schema } from './schema'
import { Organization } from '../../models/Organization'
import type { FirestoreOrganizationInstructor } from '../OrganizationInstructor/schema'
import type { FirestoreOrganizationAdmin } from '../OrganizationAdmin/schema'
import { fetchPublicUsers } from '../PublicUser'
import type { StreamInterface } from 'tricklejs/dist/types'
import type { OrganizationInstructorDetails } from '../../types'
import {
  addDocWithError,
  deleteDocWithError,
  getDocsWithError,
  getDocWithError,
  setDocWithError,
  updateDocWithError,
} from '../../firestore-mobx/fetch'
import { OrganizationAdmin } from '../../models/OrganizationAdmin'
import { OrganizationInstructor } from '../../models/OrganizationInstructor'

const converter: FirestoreDataConverter<FirestoreOrganization> = {
  toFirestore: (data) => {
    return data
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

const getColRef = (
  firestore: Firestore
): CollectionReference<FirestoreOrganization> => {
  return collection(firestore, 'organization').withConverter(converter)
}

const getDocRef = (
  firestore: Firestore,
  organizationId: string
): DocumentReference<FirestoreOrganization> => {
  return doc(getColRef(firestore), organizationId)
}

export const getOrganizations = (repository: FirebaseRepository) => {
  const ref = getColRef(repository.firestore)
  // -1 is deleted, rest are viewable
  const q = query(ref, where('organizationState', '>=', 0))
  return modelListStream(repository, q, Organization)
}

export const getOrganization = (
  repository: FirebaseRepository,
  { organizationId }: { organizationId: string }
) => {
  const ref = getColRef(repository.firestore)
  const docRef = doc(ref, organizationId)
  return modelItemStream(repository, docRef, Organization)
}

export const createOrganization = async (
  repository: FirebaseRepository,
  {
    institutionId,
    organizationName,
    organizationInstitution,
    organizationInvoiceStatus,
    catalogIds,
  }: {
    institutionId: string
    organizationName: string
    organizationInstitution: string
    organizationInvoiceStatus: OrganizationInvoiceStatus
    catalogIds: string[]
  }
) => {
  const colRef = getColRef(repository.firestore)

  // create organization doc
  const organizationRef = await addDocWithError(colRef, {
    organizationName,
    organizationInvoiceStatus,
    organizationInstitution,
    organizationState: OrganizationState.active,
    institutionId: institutionId,
    updatedAt: serverTimestamp(),
  })

  // after doc add add all the catalogs
  await Promise.all(
    catalogIds.map((catalogId) => {
      return addCatalogToOrganization(repository, {
        catalogId,
        organizationId: organizationRef.id,
        defaultCatalogId: false,
      })
    })
  )

  return organizationRef.id
}

export const getOrganizationInstructors = (
  repository: FirebaseRepository,
  {
    organizationId,
  }: {
    organizationId: string
  }
): StreamInterface<Array<OrganizationInstructorDetails>> => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(
    orgDocRef,
    'organization_instructor'
  ) as CollectionReference<FirestoreOrganizationInstructor>

  return collectionSnapshots<FirestoreOrganizationInstructor>(
    userColRef
  ).asyncMap(async (snapshot) => {
    const orgInstructors = snapshot.docs.map((doc) => doc.data())
    const userIds = snapshot.docs.map((doc) => doc.id)

    const orgInstructorsLookup = new Map(
      (orgInstructors || []).map((instructor) => [
        instructor.userId,
        instructor,
      ])
    )

    const publicUsers = await fetchPublicUsers(repository, { userIds })
    const publicUserLookup = new Map(publicUsers.map((user) => [user.id, user]))

    const data = userIds
      .map((userId) => {
        const publicUser = publicUserLookup.get(userId)
        const orgInstructor = orgInstructorsLookup.get(userId)
        if (!publicUser || !orgInstructor) {
          return null
        }

        return { orgInstructor, publicUser }
      })
      .filter((detail) => detail !== null)

    return data as Array<OrganizationInstructorDetails>
  })
}

export const getOrganizationAdmins = (
  repository: FirebaseRepository,
  {
    organizationId,
  }: {
    organizationId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(
    orgDocRef,
    'organization_admin'
  ) as CollectionReference<FirestoreOrganizationAdmin>

  return collectionSnapshots<FirestoreOrganizationAdmin>(userColRef).asyncMap(
    async (snapshot) => {
      const userIds = snapshot.docs.map((doc) => doc.id)
      const publicUsers = await fetchPublicUsers(repository, { userIds })
      return publicUsers
    }
  )
}

export const updateOrganization = async (
  repository: FirebaseRepository,
  organizationId: string,
  {
    organizationName,
    organizationCatalogIds,
    organizationDefaultCatalogIds,
    currentOrganizationCatalogIds,
    organizationInvoiceStatus,
    currentOrganizationDefaultCatalogIds,
  }: {
    organizationName: string
    organizationInvoiceStatus: OrganizationInvoiceStatus
    organizationCatalogIds: string[]
    organizationDefaultCatalogIds: string[]
    currentOrganizationCatalogIds: string[]
    currentOrganizationDefaultCatalogIds: string[]
  }
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, organizationId)

  const organizationCatalogsToDelete = currentOrganizationCatalogIds.filter(
    (id) => !organizationCatalogIds.includes(id)
  )
  const organizationCatalogsToCreate = organizationCatalogIds.filter(
    (id) => !currentOrganizationCatalogIds.includes(id)
  )
  const organizationCatalogIdsToSetDefault =
    organizationDefaultCatalogIds.filter(
      (id) =>
        !organizationCatalogsToCreate.includes(id) &&
        !currentOrganizationDefaultCatalogIds.includes(id)
    )
  const organizationCatalogIdsToUnsetDefault =
    currentOrganizationDefaultCatalogIds.filter(
      (id) =>
        !organizationCatalogsToDelete.includes(id) &&
        !organizationDefaultCatalogIds.includes(id)
    )

  // run transaction
  await runTransaction(repository.firestore, async (transaction) => {
    transaction.update(docRef, {
      organizationName,
      organizationInvoiceStatus,
      updatedAt: serverTimestamp(),
    })

    organizationCatalogsToDelete.forEach((id) => {
      transaction.delete(doc(collection(docRef, 'organization_catalog'), id))
    })
    organizationCatalogsToCreate.forEach((id) => {
      transaction.set(doc(collection(docRef, 'organization_catalog'), id), {
        organizationId,
        updatedAt: serverTimestamp(),
        catalogId: id,
        defaultCatalogId: organizationDefaultCatalogIds.includes(id),
      })
    })
    organizationCatalogIdsToSetDefault.forEach((id) => {
      transaction.update(doc(collection(docRef, 'organization_catalog'), id), {
        updatedAt: serverTimestamp(),
        defaultCatalogId: true,
      })
    })
    organizationCatalogIdsToUnsetDefault.forEach((id) => {
      transaction.update(doc(collection(docRef, 'organization_catalog'), id), {
        updatedAt: serverTimestamp(),
        defaultCatalogId: false,
      })
    })
  })
}

export const updateOrganizationState = async (
  repository: FirebaseRepository,
  organizationId: string,
  organizationState: OrganizationState
) => {
  const colRef = getColRef(repository.firestore)
  const docRef = doc(colRef, organizationId)
  return updateDocWithError(
    docRef,
    {
      organizationState,
      updatedAt: serverTimestamp(),
    },
    'UpdateOrganizationStateError'
  )
}

export const addOrganizationalAdminToOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_admin')
  // add doc at user id
  const docRef = doc(userColRef, userId)
  setDocWithError(
    docRef,
    {
      updatedAt: serverTimestamp(),
      userId,
      organizationId,
    },
    {
      errorName: 'AddOrganizationalAdminToOrganizationError',
    }
  )
}

export const addInstructorToOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_instructor')
  // add doc at user id
  const docRef = doc(userColRef, userId)
  setDocWithError(
    docRef,
    {
      updatedAt: serverTimestamp(),
      userId,
      organizationId,
      autoApproveInvoice: false,
    },
    {
      errorName: 'AddInstructorToOrganizationError',
    }
  )
}

export const removeOrganizationalAdminFromOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_admin')
  const docRef = doc(userColRef, userId)
  return deleteDocWithError(
    docRef,
    'RemoveOrganizationalAdminFromOrganizationError'
  )
}

export const removeInstructorFromOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    userId,
  }: {
    organizationId: string
    userId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_instructor')
  const docRef = doc(userColRef, userId)
  return deleteDocWithError(docRef, 'RemoveInstructorFromOrganizationError')
}

export const fetchOrganizationsWithAdminAndInstructorIds = async (
  repository: FirebaseRepository
) => {
  // get all orgs
  const orgs = (
    await getDocsWithError(
      getColRef(repository.firestore),
      'FetchOrganizationsWithAdminAndInstructorIdsError'
    )
  ).docs

  const allOrgAdminsPromise = (async () => {
    return (
      await getDocsWithError(
        collectionGroup(repository.firestore, 'organization_admin'),
        'FetchOrganizationAdminsError'
      )
    ).docs.map((d) => d.data())
  })()

  const allOrgInstructorsPromise = (async () => {
    return (
      await getDocsWithError(
        collectionGroup(repository.firestore, 'organization_instructor'),
        'FetchOrganizationInstructorsError'
      )
    ).docs.map((d) => d.data())
  })()

  const [allOrgAdmins, allOrgInstructors] = await Promise.all([
    allOrgAdminsPromise,
    allOrgInstructorsPromise,
  ])

  const orgsWithInstructsAndAdmins = orgs.map((org) => ({
    ...org.data(),
    id: org.id,
    admins: new Set() as Set<string>,
    instructors: new Set() as Set<string>,
  }))

  // convert above arr to object with id as key
  const orgsById = orgsWithInstructsAndAdmins.reduce(
    (acc, org) => {
      acc[org.id] = org
      return acc
    },
    {} as Record<string, (typeof orgsWithInstructsAndAdmins)[0]>
  )

  // iterate over all org admins and add them to the appropriate org
  allOrgAdmins.forEach((admin) => {
    if (!('organizationId' in admin) || !(admin.organizationId in orgsById))
      return
    const org = orgsById[admin.organizationId]
    org.admins.add(admin.userId)
  })

  // iterate over all org instructors and add them to the appropriate org
  allOrgInstructors.forEach((instructor) => {
    if (
      !('organizationId' in instructor) ||
      !(instructor.organizationId in orgsById)
    )
      return
    const org = orgsById[instructor.organizationId]
    org.instructors.add(instructor.userId)
  })

  // convert sets to arrays and return data
  return Object.values(orgsById).map((org) => ({
    ...org,
    admins: Array.from(org.admins),
    instructors: Array.from(org.instructors),
  }))
}

export const fetchAllOrganizationAssociatedWithUserId = async (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgAdminQuery = query(
    collectionGroup(repository.firestore, 'organization_admin'),
    where('userId', '==', userId)
  ) as Query<FirestoreOrganizationAdmin, DocumentData>

  const orgInstructorQuery = query(
    collectionGroup(repository.firestore, 'organization_instructor'),
    where('userId', '==', userId)
  ) as Query<FirestoreOrganizationInstructor, DocumentData>

  const [orgAdmins, orgInstructors] = await Promise.all([
    getDocsWithError(orgAdminQuery),
    getDocsWithError(orgInstructorQuery),
  ])

  const concatted = [
    ...orgAdmins.docs.map((admin) => admin.data().organizationId),
    ...orgInstructors.docs.map(
      (instructor) => instructor.data().organizationId
    ),
  ]
  const orgIds = Array.from(new Set(concatted))

  return fetchOrganizationsByIds(repository, orgIds)
}

export const getOrganizationAdminsByUserId = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_admin'),
    where('userId', '==', userId)
  ) as Query<FirestoreOrganizationAdmin, DocumentData>

  return modelListStream(repository, orgsQuery, OrganizationAdmin)
}

export const getOrganizationInstructorsByUserId = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_instructor'),
    where('userId', '==', userId)
  ) as Query<FirestoreOrganizationInstructor, DocumentData>

  return modelListStream(repository, orgsQuery, OrganizationInstructor)
}

export const fetchOrganizationsByIds = async (
  repository: FirebaseRepository,
  organizationIds: string[]
) => {
  const promises = organizationIds.map(async (id) => {
    const docRef = getDocRef(repository.firestore, id)
    const docSnap = await getDocWithError(docRef)
    if (!docSnap.exists()) {
      return null
    }
    return docSnap
  })

  const results = await Promise.all(promises)
  return results.flat().filter((doc) => doc !== null)
}

export const getOrganizationsWhereUserIsAdmin = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_admin'),
    where('userId', '==', userId)
  )

  return collectionSnapshots(orgsQuery).asyncMap(async (snapshot) => {
    const orgs = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const parentDoc = await getDocWithError(
          doc.ref.parent.parent!.withConverter(converter),
          'FetchOrganizationError'
        )
        return convertDocumentSnapshotToModel(
          repository,
          parentDoc,
          Organization
        )
      })
    )
    return orgs
  })
}

export const getOrganizationsWhereUserIsInstructor = (
  repository: FirebaseRepository,
  userId: string
) => {
  const orgsQuery = query(
    collectionGroup(repository.firestore, 'organization_instructor'),
    where('userId', '==', userId)
  )
  return collectionSnapshots(orgsQuery).asyncMap(async (snapshot) => {
    const orgs = await Promise.all(
      snapshot.docs.map(async (doc) => {
        const parentDoc = await getDocWithError(
          doc.ref.parent.parent!.withConverter(converter),
          'FetchOrganizationError'
        )
        return convertDocumentSnapshotToModel(
          repository,
          parentDoc,
          Organization
        )
      })
    )
    return orgs
  })
}

export const getInstructorIdsForOrganization = (
  repository: FirebaseRepository,
  organizationId: string
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const userColRef = collection(orgDocRef, 'organization_instructor')
  return collectionSnapshots(userColRef).asyncMap(async (snapshot) => {
    return snapshot.docs.map((doc) => doc.id)
  })
}

export const getOrganizationsForCatalogWithInstructorIds = (
  repository: FirebaseRepository,
  {
    catalogId,
  }: {
    catalogId: string
  }
) => {
  const orgCatalogsQuery = query(
    collectionGroup(repository.firestore, 'organization_catalog'),
    where('catalogId', '==', catalogId)
  )
  return collectionSnapshots(orgCatalogsQuery).asyncMap(
    async (orgsSnapshot) => {
      const orgsWithInstructorCount = await Promise.all(
        orgsSnapshot.docs.map(async (doc) => {
          const parentOrgDocRequest = getDocWithError(
            doc.ref.parent.parent!.withConverter(converter)
          )
          const orgInstructorsColRef = collection(
            doc.ref.parent.parent!,
            'organization_instructor'
          )
          const instructorsRequest = getDocsWithError(orgInstructorsColRef)
          const [orgDoc, orgInstructorDocs] = await Promise.all([
            parentOrgDocRequest,
            instructorsRequest,
          ])
          return {
            organization: convertDocumentSnapshotToModel(
              repository,
              orgDoc,
              Organization
            ),
            instructorIds: orgInstructorDocs.docs.map((doc) => doc.id),
          }
        })
      )
      return orgsWithInstructorCount
    }
  )
}

export const removeCatalogFromOrganization = async (
  repository: FirebaseRepository,
  {
    organizationId,
    catalogId,
  }: {
    organizationId: string
    catalogId: string
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const catalogDocRef = doc(
    collection(orgDocRef, 'organization_catalog'),
    catalogId
  )
  return deleteDocWithError(catalogDocRef)
}

export const addCatalogToOrganization = async (
  repository: FirebaseRepository,
  {
    catalogId,
    organizationId,
    defaultCatalogId,
  }: {
    catalogId: string
    organizationId: string
    defaultCatalogId: boolean
  }
) => {
  const orgDocRef = doc(getColRef(repository.firestore), organizationId)
  const catalogDocRef = doc(
    collection(orgDocRef, 'organization_catalog'),
    catalogId
  )
  return setDocWithError(catalogDocRef, {
    organizationId,
    catalogId,
    defaultCatalogId,
    updatedAt: serverTimestamp(),
  })
}
