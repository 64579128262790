import type { CollectionReference, DocumentData } from 'firebase/firestore'
import {
  collection,
  doc,
  type Firestore,
  type FirestoreDataConverter,
  type QueryDocumentSnapshot,
} from 'firebase/firestore'
import { type FirestoreUserProfileSectionPass, schema } from './schema'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { modelItemStream, modelListStream } from '../../firestore-mobx/stream'
import { UserProfileSectionPass } from '../../models/UserProfileSectionPass'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreUserProfileSectionPass> = {
  toFirestore: () => {
    throw new Error('unimplemented')
  },
  fromFirestore: (snapshot: QueryDocumentSnapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

export const getColRef = (
  firestore: Firestore,
  userId: string
): CollectionReference<FirestoreUserProfileSectionPass, DocumentData> => {
  return collection(
    firestore,
    'user_profile',
    userId,
    'section_pass'
  ).withConverter(converter)
}

export const getDocRef = (
  firestore: Firestore,
  { userId, sectionId }: { userId: string; sectionId: string }
) => {
  const col = getColRef(firestore, userId)
  return doc(col, sectionId)
}

export const getSectionPassForSection = (
  repository: FirebaseRepository,
  { userId, sectionId }: { userId: string; sectionId: string }
) => {
  const docRef = getDocRef(repository.firestore, { userId, sectionId })

  return modelItemStream(repository, docRef, UserProfileSectionPass)
}

export const getSectionPasses = (
  repository: FirebaseRepository,
  { userId }: { userId: string }
) => {
  return modelListStream(
    repository,
    getColRef(repository.firestore, userId),
    UserProfileSectionPass
  )
}
