import type {
  DocumentData,
  DocumentReference,
  Firestore,
  FirestoreDataConverter,
  PartialWithFieldValue,
  Query,
} from 'firebase/firestore'
import {
  and,
  collection,
  collectionGroup,
  doc,
  query,
  where,
} from 'firebase/firestore'
import { modelListStream } from '../../firestore-mobx/stream'
import type { FirebaseRepository } from '../../models/FirebaseRepository'
import { SectionRequest } from '../../models/SectionRequest'
import type { FirestoreSectionRequest } from './schema'
import { schema, writeSchema } from '.'
import { addDocWithError, updateDocWithError } from '../../firestore-mobx/fetch'

export * from './schema'

const converter: FirestoreDataConverter<FirestoreSectionRequest> = {
  toFirestore: (data: PartialWithFieldValue<FirestoreSectionRequest>) => {
    writeSchema.partial().parse(data)

    return data
  },
  fromFirestore: (snapshot) => {
    const data = snapshot.data({ serverTimestamps: 'estimate' })
    return schema.parse(data)
  },
}

function getColRef(firestore: Firestore, sectionId: string) {
  return collection(
    firestore,
    'section',
    sectionId,
    'section_request'
  ).withConverter(converter)
}

function getDocRef(
  firestore: Firestore,
  sectionId: string,
  requestId: string
): DocumentReference<FirestoreSectionRequest, DocumentData> {
  return doc(getColRef(firestore, sectionId), requestId)
}

function getColGroupRef(firestore: Firestore): Query<FirestoreSectionRequest> {
  return collectionGroup(firestore, 'section_request').withConverter(converter)
}

export type SectionRequestFilters = {
  includeStatuses?: FirestoreSectionRequest['sectionRequestState'][]
  excludeStatuses?: FirestoreSectionRequest['sectionRequestState'][]
}

export const getAllSectionRequestsByOrganizationId = (
  repository: FirebaseRepository,
  organizationId: string
) => {
  const groupColRef = getColGroupRef(repository.firestore)

  const constraints = [where('organizationId', '==', organizationId)]
  // I need to do this trick because otherwise Firebase complains of missing index
  constraints.push(where('sectionRequestState', '>=', -1))

  const q = query(groupColRef, and(...constraints))

  return modelListStream(repository, q, SectionRequest)
}

export const getSectionRequestsByOrganizationId = (
  repository: FirebaseRepository,
  organizationId: string,
  filter: 'pending' | 'not_completed'
) => {
  const groupColRef = getColGroupRef(repository.firestore)

  const constraints = [where('organizationId', '==', organizationId)]

  if (filter === 'pending') {
    constraints.push(where('sectionRequestState', '==', 0))
  } else {
    constraints.push(where('sectionRequestState', '!=', 0))
  }

  const q = query(groupColRef, and(...constraints))

  return modelListStream(repository, q, SectionRequest)
}

export const getSectionRequestsBySectionId = (
  repository: FirebaseRepository,
  sectionId: string
) => {
  const colRef = getColRef(repository.firestore, sectionId)

  return modelListStream(repository, colRef, SectionRequest)
}

export const withdrawSectionRequest = async (
  repository: FirebaseRepository,
  sectionId: string,
  requestId: string
) => {
  const docRef = getDocRef(repository.firestore, sectionId, requestId)

  await updateDocWithError(docRef, {
    sectionRequestState: -1,
    updatedAt: new Date(),
  })
}

export const createSectionInvoiceRequest = async (
  repository: FirebaseRepository,
  sectionId: string,
  sectionRequest: FirestoreSectionRequest
) => {
  const colRef = getColRef(repository.firestore, sectionId)

  await addDocWithError(colRef, sectionRequest)
}

export const updateSectionRequestState = async (
  repository: FirebaseRepository,
  sectionId: string,
  sectionRequestId: string,
  updatedByUserId: string,
  sectionRequestState: FirestoreSectionRequest['sectionRequestState'],
  sectionRequestReason: string
) => {
  const docRef = getDocRef(repository.firestore, sectionId, sectionRequestId)

  await updateDocWithError(docRef, {
    sectionRequestState: sectionRequestState,
    sectionRequestReason,
    updatedByUserId,
    updatedAt: new Date(),
  })
}
