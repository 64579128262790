import { z } from 'zod'
import {
  nullSafeOptionalFirestoreTimestamp,
  nullSafeOptionalString,
} from '../shared/schema'

export const schema = z.object({
  stripeProductId: nullSafeOptionalString(),
  sectionPassPrice: z
    .string()
    .refine(
      (val) => !isNaN(parseInt(val, 10)) && Number.isInteger(parseFloat(val)),
      {
        message: 'sectionPassPrice must be a valid integer string',
      }
    ),
  updatedAt: nullSafeOptionalFirestoreTimestamp(),
})

export const empty = Object.freeze(
  schema.parse({
    stripeProductId: '',
    sectionPassPrice: '0',
    updatedAt: null,
  })
)

export interface FirestoreSettingsSectionPassPricing
  extends z.infer<typeof schema> {}
