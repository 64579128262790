import type { MobxDocument } from '../firestore-mobx'
import {
  ObservableModelWithDecorators,
  emptyCollection,
  emptyModel,
} from '../firestore-mobx/model'
import { empty } from '../firestore/RoomStateInspector'
import type { FirestoreRoomStateInspector } from '../firestore/RoomStateInspector'
import type { FirebaseRepository } from './FirebaseRepository'

export class RoomStateInspector extends ObservableModelWithDecorators<FirestoreRoomStateInspector> {
  static empty(repository: FirebaseRepository) {
    return emptyModel(repository, this, empty)
  }

  static emptyCollection(repository: FirebaseRepository) {
    return emptyCollection(repository, this, empty)
  }

  constructor(
    repository: FirebaseRepository,
    doc: MobxDocument<FirestoreRoomStateInspector>
  ) {
    super(repository, doc)
  }
}
