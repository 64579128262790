import z from 'zod'
import { nullSafeBoolean } from '../shared/schema'

export const schema = z.object({
  roomInspector: nullSafeBoolean(false),
})

export const empty = Object.freeze(
  schema.parse({
    roomInspector: false,
  })
)

export type FirestoreSettingsSuperAdmin = z.infer<typeof schema>
